<template>
  <div class="animated fadeIn">
    <h4 class="mb-3">
      Рейтинг
      <span
        v-if="ratingDetail.rating"
        :class="{'rating-low': ratingDetail.rating <= 30, 'rating-mid': ratingDetail.rating >= 31 && ratingDetail.rating <= 49, 'rating-high': ratingDetail.rating >= 50}"
      > {{ ratingDetail.rating }}</span>
    </h4>
    <h5><span class="icon-chart" /> График изменения рейтинга</h5>
    &nbsp;
    <b-card-header class="d-flex justify-content-between">
      <h5>
        <b-button
          v-b-popover.hover.top="'Отдалить'"
          class="icon-minus"
          :disabled="ratingScopedLabels.length == 0"
          @click="zoomOut"
        />
        &nbsp;
        <b-button
          v-b-popover.hover.top="'Приблизить'"
          class="icon-plus"
          :disabled="chartData.labels.length == 1"
          @click="zoomIn"
        />
        &nbsp;
        <b-button
          v-b-popover.hover.top="'Прокрутить назад'"
          class="icon-arrow-left"
          :disabled="ratingScopedLabels.length == 0 || currentPageNumber == 1"
          @click="goPrevious"
        />
        &nbsp;
        <b-button
          v-b-popover.hover.top="'Прокрутить вперёд'"
          class="icon-arrow-right"
          :disabled="ratingScopedLabels.length == 0 || currentPageNumber == pageMaxCount"
          @click="goNext"
        />
        &nbsp;
      </h5>
      <h5>
        <el-date-picker
          v-model="dateVal"
          type="daterange"
          align="right"
          start-placeholder="Начальная дата"
          end-placeholder="Конечная дата"
          format="dd.MM.yyyy"
          :default-value="'2020-12-14'"
          :picker-options="pickerOptions"
          @change="selectPeriodByDate(ratingPeriods.value, dateVal)"
        />
      </h5>
      <h5>
        <el-select
          v-model="ratingPeriods.value"
          @change="selectPeriod"
        >
          <el-option
            v-for="item in ratingPeriods.options"
            :key="item.value"
            :label="item.text"
            :value="item.value"
          />
        </el-select>
      </h5>
    </b-card-header>
    <b-card>
      <div style="max-height: 500px">
        <rating-chart
          v-if="chartLoad"
          :chart-data="chartData"
          @index-select="reloadChartDetail"
        />
      </div>
    </b-card>
    <b-tabs
      justified
      tabs
    >
      <b-tab
        title="Детализация рейтинга"
      >
        <b-row>
          <b-col class="rating-info-coll">
            <b>Период расчета рейтинга:</b> {{ formatDate(ratingDetail.dateFrom) +
              ' - ' + formatDate(ratingDetail.dateTo) }}
          </b-col>
          <b-col
            v-for="(item, index) in ratingDetail.ratingIndexValues"
            :key="index"
            class="rating-info-coll"
          >
            <b>{{ item.index.ratingIndex.name }}:</b>
            <span v-if="item.calculation.countNoDelayed">
              {{ item.calculation.countNoDelayed/item.calculation.countRoutes*100 }} %
            </span>
            <span v-else-if="item.calculation.countAuctions">
              {{ item.calculation.countAuctions }}
            </span>
            <span v-else>
              {{ item.calculation.countRoutes }}
            </span>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="rating-info-coll">
            <b> Итоговый рейтинг:</b>
            <span
              v-if="ratingDetail.rating"
              :class="{'rating-low': ratingDetail.rating <= 30, 'rating-mid': ratingDetail.rating >= 31 && ratingDetail.rating <= 49, 'rating-high': ratingDetail.rating >= 50}"
            > {{ ratingDetail.rating }}</span>
          </b-col>
        </b-row>
        <div v-if="ratingDetail.ratingIndexValues">
          <customer-contractor-rating-detail-table
            v-if="ratingDetail.ratingIndexValues"
            :rating-index-values="ratingDetail.ratingIndexValues"
            :contractor-id="this.contractorId"
          />
        </div>
      </b-tab>
      <b-tab
        title="Поданные апелляции"
      >
        <div v-if="appeals">
          <contractor-rating-appeals-table
            :rating-index-values="ratingDetail.ratingIndexValues"
            @reload-detail="reloadDetail(this.ratingDetail.id)"
          />
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Vue from 'vue';

import RatingChart from './RatingChart';
import {
  customerContractorRatingList,
  customerContractorRatingDetail, customerContractorRatingDetailById, ratingPeriods,

} from '../../services/api';
import moment from 'moment';
import {ClientTable} from 'vue-tables-2';
import ContractorRatingAppealsTable from './ContractorRatingAppealsTable';
import CustomerContractorRatingDetailTable from './CustomerContractorRatingDetailTable';
import {URLToQuery} from '../../services/http';

Vue.use(ClientTable);

export default {
  name: 'CustomerContractorRating',
  components: {
    CustomerContractorRatingDetailTable,
    ContractorRatingAppealsTable,
    RatingChart,
  },
  props: {
    contractorId: {
      type: String,
    },
  },
  data() {
    return {
      cantGoPrevious: false,
      cantGoNext: true,
      currentPageNumber: 1,
      appeals: [],
      ratingList: [],
      ratingScopedLabels: [],
      ratingDetail: {},
      ratingData: {},
      periodDates: ['2020-12-14', '2020-12-21'],
      dateVal: null,
      pickerOptions: {
        firstDayOfWeek: 1,
      },
      ratingPeriods: {
        options: [],
        value: '',
      },
      chartLoad: true,
      chartOptions: {
        reactive: true,
        onClick: this.handleClick,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
            },
            gridLines: {
              display: false,
            },
          }],
          xAxes: [{
            gridLines: {
              display: true,
            },
          }],
        },
      },
    };
  },
  computed: {
    chartData: function() {
      const labels = [];
      const data = [];
      for (const [key, rating] of Object.entries(this.ratingData)) {
        labels.push(key);
        data.push(rating);
      }
      return {

        labels,
        datasets: [
          {
            label: 'Рейтинг',
            data,
          },
        ],
      };
    },
  },
  async mounted() {
    await this.getContractorRatingList();
    await this.getRatingDetail();
    const params = URLToQuery(this.$route.fullPath);
    console.log(params);
    if (params.detail && parseInt(params.detail)) {
      this.reloadDetail(params.detail);
    }
    await this.getRatingPeriods();
    this.init();
  },
  methods: {
    init() {
      this.pageMaxCount = this.ratingList.length;
    },
    zoomIn() {
      this.ratingScopedLabels.push(this.chartData.labels.splice(this.chartData.datasets.length, 1));
      this.chartLoad = false;
      this.$nextTick(() => {
        this.chartLoad = true;
      });
    },
    zoomOut() {
      if (this.currentPageNumber > 1) {
        if (this.ratingScopedLabels.length > 1) {
          this.chartData.labels.push(this.ratingScopedLabels.splice(this.ratingScopedLabels.length - 1, 1));
        }
        this.chartData.labels.unshift(this.ratingScopedLabels.splice(this.currentPageNumber - 2, 1));
        this.currentPageNumber--;
      } else {
        this.chartData.labels.push(this.ratingScopedLabels.splice(this.ratingScopedLabels.length - 1, 1));
      }
      this.chartLoad = false;
      this.$nextTick(() => {
        this.chartLoad = true;
      });
    },
    goNext() {
      this.currentPageNumber++;
      this.ratingScopedLabels.unshift(this.chartData.labels.splice(0, 1));
      this.chartData.labels.push(this.ratingScopedLabels.splice(this.ratingScopedLabels.length - 1, 1));
      this.chartLoad = false;
      this.$nextTick(() => {
        this.chartLoad = true;
      });
    },
    goPrevious() {
      this.currentPageNumber--;
      this.ratingScopedLabels.push(this.chartData.labels.splice(this.chartData.labels.length - 1, 1));
      this.chartData.labels.unshift(this.ratingScopedLabels.splice(0, 1));
      this.chartLoad = false;
      this.$nextTick(() => {
        this.chartLoad = true;
      });
    },
    selectPeriod(id) {
      this.getContractorRatingList(id);
    },
    selectPeriodByDate(id, dateVal) {
      if (dateVal) {
        const dateFrom = moment(dateVal[0]).format('DD.MM.YYYY');
        const dateTo = moment(dateVal[1]).format('DD.MM.YYYY');
        this.getContractorRatingList(id, dateFrom, dateTo);
      }
    },
    formatDate(dateString) {
      return moment(dateString).format('DD.MM.YYYY');
    },
    reloadChartDetail(index) {
      this.reloadDetail(this.ratingList[index].id);
    },
    async reloadDetail(id) {
      const response = await customerContractorRatingDetailById(this.$store.state.user.ownerId, this.contractorId, id);
      if (response && response.status === 200) {
        this.ratingDetail = response.data;
        const appeals = [];
        this.ratingDetail.ratingIndexValues.forEach((ratingIndexValue)=>{
          ratingIndexValue.ratingIndexValueAppeals.forEach( (appeal) =>appeals.push(appeal));
        });
        this.appeals = appeals;
      }
    },
    async getContractorRatingList(periodId = 1, dateFrom, dateTo) {
      if (this.currentPageNumber > 0) {
        const params = {page: this.currentPageNumber};
        if (dateFrom) {
          params.dateFrom = dateFrom;
        }
        if (dateTo) {
          params.dateTo = dateTo;
        }
        if (this.dateVal) {
          params.dateFrom = moment(this.dateVal[0]).format('DD.MM.YYYY');
          params.dateTo = moment(this.dateVal[1]).format('DD.MM.YYYY');
        }
        const response = await customerContractorRatingList(
          this.$store.state.user.ownerId,
          this.contractorId,
          params,
          periodId,
        );
        if (response && response.status === 200) {
          this.ratingList =[];
          this.ratingData = {};
          response.data.items.reverse().forEach((item) => {
            this.ratingList.push(item);
            const period = moment(item.dateFrom).format('DD.MM.YYYY') +
                            ' - ' + moment(item.dateTo).format('DD.MM.YYYY');
            this.$set(this.ratingData, period, item.rating);
          });
          if (this.currentPageNumber === 1) {
            this.cantGoNext = true;
          } else {
            this.cantGoNext = false;
          }
          if (response.data.totalCount - response.data.currentPageNumber * response.data.numItemsPerPage > 0) {
            this.cantGoPrevious = false;
          } else {
            this.cantGoPrevious = true;
          }
          this.currentPageNumber = response.data.currentPageNumber;
          this.init();
        }
      }
    },
    async getRatingDetail() {
      const response = await customerContractorRatingDetail(this.$store.state.user.ownerId, this.contractorId);
      if (response && response.status === 200) {
        this.ratingDetail = response.data;
        const appeals = [];
        this.ratingDetail.ratingIndexValues.forEach((ratingIndexValue)=>{
          ratingIndexValue.ratingIndexValueAppeals.forEach( (appeal) =>appeals.push(appeal));
        });
        this.appeals = appeals;
      }
    },
    async getRatingPeriods() {
      const response = await ratingPeriods();
      if (response && response.status === 200) {
        this.ratingPeriods.options = response.data.items.map((item) => {
          return {value: item.id, text: item.name};
        });
        this.ratingPeriods.value = 1;
      }
    },
  },
};
</script>

<style scoped>
.rating-info-coll{
  padding: 15px;
}
</style>
