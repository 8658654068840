<template>
  <div>
    <v-client-table
      v-if="ratingIndexValues"
      :columns="columns"
      :options="options"
      :theme="theme"
      :data="ratingIndexValues"
    >
      <span
        slot="name"
        slot-scope="props"
      >
        {{ props.row.index.ratingIndex.name }}
        <span
          :id="'popover-reactive-'+props.row.id"
          class="el-icon-info"
        />
        <b-popover
          ref="popover"
          :target="'popover-reactive-'+props.row.id"
          triggers="hover"
          placement="auto"
          container="my-container"
        >
          <template v-slot:title>
            {{ props.row.index.ratingIndex.name }}
          </template>

          <div v-if="props.row.index.formula">
            <p><b>Расчет производится по формуле по формуле:</b></p>
            <p>{{ props.row.index.formula }}</p>
          </div>
          <div v-else-if="props.row.index.indexOptions">
            <rating-index-options-table
              :option-value-name="props.row.index.name"
              :rating-index-options="props.row.index.indexOptions.sort((a, b) => {return a.min - b.min;})"
            />
          </div>
        </b-popover>
      </span>
      <span
        slot="period"
        slot-scope="props"
      >
        {{ formatDate(props.row.dateFrom) +
          ' - ' + formatDate(props.row.dateTo) }}
      </span>
      <span
        slot="factor"
        slot-scope="props"
      >
        {{ props.row.index.koeff }}
      </span>
      <span
        slot="rating"
        slot-scope="props"
      >
        {{ props.row.rating }}
        <span v-if="props.row.bonus">
          <span class="text-success">(+{{ props.row.bonus }}) </span>
          <span
            v-b-popover.hover="{title:'Комментарий к начисленному бонусу:', content:props.row.bonusComment}"
            class="el-icon-info"
          />
        </span>
      </span>
      <span
        slot="result"
        slot-scope="props"
      >
        {{ ((props.row.rating + props.row.bonus)*props.row.index.koeff/totalKoeff).toFixed(2) }}
      </span>
      <span
        slot="actions"
        slot-scope="props"
      >
        <b-button
          variant="success"
          @click="showBonusModal(props.row)"
        >
          Изменить бонус
        </b-button>
      </span>
    </v-client-table>
    <b-modal
      v-model="bonusModal.show"
      title="Изменение бонуса"
      class="modal-warning"
      cancel-title="Назад"
      ok-variant="success"
      :ok-disabled="bonusModalDisabled"
      ok-title="Изменить бонусный бал"
      @ok="sendBonus()"
    >
      <p>Тип показателя: {{ bonusModal.type }}</p>
      <b-form-group
        class="form-group-el-select"
        label="Бонусный бал"
      >
        <b-form-input
          v-model="bonusModal.bonus"
          type="number"
          :max="bonusModal.maxBonus"
        />
      </b-form-group>
      <b-form-group
        class="form-group-el-select"
        label="Комментарий"
      >
        <b-form-textarea v-model="bonusModal.comment" />
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import {ClientTable} from 'vue-tables-2';
import moment from 'moment';
import RatingIndexOptionsTable from './RatingIndexOptionsTable';
import {contractorIndexValueAppeal, customerContractorRatingAddBonusById} from '../../services/api';
import notifications from '../../components/mixins/notifications';

Vue.use(ClientTable);
export default {
  name: 'CustomerContractorRatingDetailTable',
  components: {RatingIndexOptionsTable},
  mixins: [notifications],
  props: ['ratingIndexValues', 'contractorId'],
  data() {
    return {
      bonusModal: {
        bonus: 0,
        maxBonus: 0,
        show: false,
        disabled: true,
        type: '',
        comment: '',
        indexValueId: null,
      },
      theme: 'bootstrap4',
      columns: ['name', 'period', 'rating', 'factor', 'result', 'actions'],
      options: {
        headings: {
          'name': 'Название',
          'period': 'Период расчета',
          'rating': 'Количество баллов',
          'factor': 'Коэффициент уд. веса',
          'result': 'Итого баллов',
          'actions': '',
        },
        sortable: [],
        filterable: [],
        sortIcon: {
          base: 'fa',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc',
          is: 'fa-sort',
        },
        pagination: false,
        texts: {
          count: '',
          first: 'Первая',
          last: 'Последняя',
          filter: 'Фильтр:',
          filterPlaceholder: 'Поисковый запрос',
          limit: 'Записи:',
          page: 'Страница:',
          noResults: 'Нет совпадающих записей',
          filterBy: 'Фильтр по {column}',
          loading: 'Загрузка...',
          defaultOption: 'Выбор {column}',
          columns: 'Столбцы',
        },
      },
    };
  },
  computed: {
    bonusModalDisabled() {
      return !this.bonusModal.comment.replace(/\s/g, '') || this.bonusModal.bonus > this.bonusModal.maxBonus;
    },
    totalKoeff() {
      console.log(this.ratingIndexValues);
      let summ = 0;
      this.ratingIndexValues.forEach((indexValue)=>{
        if (indexValue.index.koeff >0) {
          summ += indexValue.index.koeff;
        }
      });
      return summ;
    },
  },
  methods: {
    formatDate(dateString) {
      return moment(dateString).format('DD.MM.YYYY');
    },
    showBonusModal(indexValue) {
      console.log(indexValue);
      this.bonusModal.show = true;

      this.bonusModal.comment = indexValue.bonusComment ? indexValue.bonusComment : '';
      this.bonusModal.bonus = indexValue.bonus ? indexValue.bonus : 0;
      this.bonusModal.maxBonus = indexValue.maxBonus;
      this.bonusModal.indexValueId = indexValue.id;
      this.bonusModal.type = indexValue.index.name;
    },
    async sendBonus() {
      const response = await customerContractorRatingAddBonusById(
        this.$store.state.user.ownerId,
        this.contractorId,
        this.bonusModal.indexValueId,
        {
          bonusComment: this.bonusModal.comment,
          bonus: this.bonusModal.bonus,
        },
      );
      if (response && response.status === 200) {
        this.showSuccess('Бонус обновлен');
        console.log('reload');
        this.$emit('reload-detail');
      }
    },
  },
};
</script>

<style scoped>

</style>
